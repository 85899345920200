<template>
  <div class="overTopicDetails">
    <div class="content">
      <Breadcrumb></Breadcrumb>
      <div class="box">
        <div class="content-left" v-loading="loading">
          <Grid v-bind="grid">
            <el-table-column label="题目名称" align="center" slot="title">
              <template slot-scope="scope">
                <el-tooltip effect="dark" placement="top" :disabled="scope.row.title.length <= 16">
                  <template slot="content">
                    <span v-html="scope.row.title"></span>
                  </template>
                  <span class="shenluehao" v-html="scope.row.title"></span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="题目名称" align="center" slot="title2">
              <template slot-scope="scope">
                <el-tooltip effect="dark" placement="top" :disabled="scope.row.title.length <= 4">
                  <template slot="content">
                    <span v-html="scope.row.title"></span>
                  </template>
                  <span class="shenluehao" v-html="scope.row.title"></span>
                </el-tooltip>
              </template>
            </el-table-column>
          </Grid>

          <div class="result_table table">
            <el-row :gutter="20" class="result_table_filtrate">
              <el-col :span="5">
                <el-select v-model="answer_log_params.type" placeholder="请选择结果" clearable size="small">
                  <el-option v-for="item in options.case_judge_reault.list" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
              <!-- <el-col :span="5">
                <el-select v-model="answer_log_params.language" placeholder="请选择语言" clearable size="small">
                  <el-option v-for="item in options.language.list" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-col> -->
              <el-col :span="5">
                <el-input placeholder="请输入姓名" v-model="answer_log_params.nickname" clearable size="small">
                </el-input>
              </el-col>
              <el-col :span="3">
                <div class="search_btn" @click="get_data({...answer_log_params,is_repeat:0})">搜 索</div>
              </el-col>
              <el-col :span="3">
                <div class="search_btn" @click="get_data({...answer_log_params,is_repeat:1})">去 重</div>
              </el-col>
            </el-row>
            <Grid v-bind="rankingList" height="530px">
              <el-table-column label="结果" align="center" slot="result" width="160">
                <template slot-scope="scope">
                  <div class="result">
                    <div class="btn btnMsg" :style="{background:scope.row.remark.color}">{{ scope.row.remark.label }}</div>
                    <div class="btn" :style="{background:scope.row.remark.color}">{{ scope.row.remark.value }}</div>
                  </div>

                </template>
              </el-table-column>
            </Grid>
          </div>

          <div class="pagination">
            <el-pagination background layout="prev, pager, next" :total="total" @current-change="currentChange" :page-size="10" :current-page="page" :hide-on-single-page="true">
            </el-pagination>
          </div>
        </div>
        <div class="content-right">
          <Advertising></Advertising>
          <HotRecommend class="margin"></HotRecommend>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Advertising from "@/components/Advertising/Advertising.vue"
import HotRecommend from "@/views/api/com/hotRecommend";
import Grid from "@/components/Grid/index.vue"
import { one_statis } from "@/api/problemList.js"
import { options} from "@/api/topic.js"
export default {
  components: {
    Advertising,
    HotRecommend,
    Grid,
  },
  data () {
    return {
      //答题详情请求筛选
      answer_log_params: {
        type: '',
        language: '',
        nickname: ''
      },
      options: { case_judge_reault: { list: [] }, language: { list: [] } },//筛选项的数据列表
      total: 0,
      loading: false,
      rankingList: {
        source: [],
        columns: [

        ],
        page: false,
      },
      grid: {
        source: [],
        columns: [

        ],
        page: false,
      },
      //非编程题
      FBCTMcolumns: [
        {
          slot: "title",
        },
        {
          prop: "AC",
          label: "解决", width: 55
        },
        {
          prop: "total",
          label: "提交",
          width: 55
        },
        {
          prop: "UKE",
          label: "格式错误",
        },
        {
          prop: "error",
          label: "答案错误",
        },
        {
          prop: "TLE",
          label: "时间超限",
        },
        {
          prop: "MLE",
          label: "内存超限",
        },
        {
          prop: "OLE",
          label: "输出超限",
        },
        {
          prop: "RE",
          label: "运行错误",
        },
        // {
        //   prop: "232",
        //   label: "输出错误",
        // },
        {
          prop: "success",
          label: "Statistics",
        }
      ],
      FBCcolumns: [
        {
          slot: 'index'
        },
        // {
        //   prop: "id",
        //   label: "提交编号",
        // },
        {
          prop: "nickname",
          label: "用户",
        },
        {
          slot: "result"
        },
        {
          prop: "memory",
          label: "运行空间",
        },
        {
          prop: "time",
          label: "运行时间",
        },

        {
          prop: "lang",
          label: "语言",
        },
        {
          prop: "code_length",
          label: "代码长度",
        },
        {
          prop: "_create_time",
          label: "提交时间",
        },
      ],

      codeColumns: [
        {
          slot: "title2",
        },
        {
          prop: "total",
          label: "提交",
        },
        {
          prop: "success",
          label: "正确",
        },
        {
          prop: "error",
          label: "答案错误",
        },
      ],
      codeColumnsBottom: [
        {
          prop: "id",
          label: "提交编号",
        },
        {
          prop: "nickname",
          label: "用户",
        },
        {
          prop: "remark",
          label: "结果",
        },
        {
          prop: "_create_time",
          label: "提交时间",
        },
      ],
      id: '',
      page: 1,
      page_size: 10,
    }
  },
  created () {
    this.id = this.$route.query.id
    options({}).then((res) => {
      this.options = res.data
    })

    this.get_data()
  },
  mounted () {

  },
  methods: {
    get_data (where = {}) {
      this.loading = true
      let type_title = this.$route.query.type_title
      one_statis({ ...where,quest_id: this.id, page_size: this.page_size, page: this.page }).then(({ data }) => {
        this.total = data.total
        this.grid = {
          source: [data.info],
          columns: type_title == '编程' ? this.FBCTMcolumns : this.codeColumns,
          page: false,
        }
        this.rankingList = {
          source: data.data,
          columns: type_title == '编程' ? this.FBCcolumns : this.codeColumnsBottom,
          page: false,
        }

      }).finally(() => {
        this.loading = false
      })
    },
    currentChange (e) {
      this.page = e
      this.get_data()
    }
  },
};
</script>

<style lang="less" scoped>
.overTopicDetails {
  .search_btn {
    font-size: 14px;
    width: 70px;
    padding: 0 4px;
    border-radius: 2px;
    height: 30px;
    background: #00957e;
    color: #fff;
    cursor: pointer;
    text-align: center;
    line-height: 30px;
  }
  .result_table {
    background-color: #fff;
    .result_table_filtrate {
      padding: 1rem;
      padding-top: 2rem;
    }
  }
  .pagination {
    text-align: center;
    margin: 2rem 0;
  }
  .result {
    display: flex;
    justify-content: center;
    .btnMsg {
      margin-right: 0.5rem;
    }
    .btn {
      border-right: 6px;
      color: #fff;
      padding: 0 6px;
    }
  }

  .box {
    display: flex;
    justify-content: space-between;

    .content-left {
      width: 70%;
      .table {
        margin-top: 40px;
      }
    }
    .content-right {
      width: 27%;
      .margin {
        margin-top: 40px;
      }
    }
  }
}
</style>
